import { Component, OnInit } from '@angular/core'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { buildResponse } from '../login/login.component'
import { environment } from 'src/environments/environment'
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'about',
    templateUrl: './about.modal.html',
    styleUrls: ['./about.modal.scss'],
    standalone: true,
    imports: [NgIf, NgFor],
})
export class AboutModal implements OnInit {
	nexusBuilds: buildResponse[]
	brand: string
	platName: string
	constructor(public session: SessionService) {
		this.nexusBuilds = this.session.nexusBuils
		this.brand = environment.brand
		this.platName = environment.platformName
	}

	ngOnInit(): void {}

	// 27.05.2021
	getBuild() {
		return Config.BUILD
	}
}

import { Component, Input, OnInit } from '@angular/core'
import { faTriangleExclamation, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { SessionService } from 'src/app/service/session.service'
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-closer-auto-relation',
    templateUrl: './closer-auto-relation.modal.html',
    styleUrls: ['./closer-auto-relation.modal.scss'],
    standalone: true,
    imports: [
        FaIconComponent,
        NgIf,
        TranslateModule,
    ],
})
export class CloserAutoRelationModalContent implements OnInit {
	@Input() public city: string
	@Input() public distance: number
	@Input() public graderId: number
	@Input() public suggestedRelId: number
	hasMultipleRelations: boolean
	confirmGraderSwitchFlag: boolean

	//icons
	faTriangleExclamation = faTriangleExclamation
	faCircleInfo = faCircleInfo

	constructor(public session: SessionService, public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		this.confirmGraderSwitchFlag = false
		this.hasMultipleRelations = this.session.user.getRelationNumber() > 1
	}

	acceptGraderSwitch() {
		if (this.confirmGraderSwitchFlag) {
			this.session.manageNewAutomaticRelation(this.graderId, true)
			this.activeModal.dismiss()
		} else {
			this.confirmGraderSwitchFlag = true
		}
	}

	refuseGraderSwitch(action: string) {
		this.session.refuseGraderSwitch(action, this.suggestedRelId)
		this.activeModal.dismiss()
	}
}

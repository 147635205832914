<div class="loader-container" [ngClass]="activateSpinner ? 'show' : '' ">
	<div *ngIf="!loaderVisionix" class="lds-ring">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
	<div *ngIf="loaderVisionix" class="lds-ring">
		<svg width="100%" height="100%" viewBox="0 0 467 407" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path id="path2" d="M345.485 131.123C374.371 213.145 361.781 283.576 305.485 289.123C364.805 316.448 409.413 287.694 423.5 240.5C437.678 174.035 404.307 130.682 345.485 131.123Z" fill="#4F0AEB" stroke="#4F0AEB" />
			<path id="path1" d="M143.369 11.663C154.954 226.922 315.595 336.051 393.37 285.663C326.413 335.556 192.339 330.149 98.5001 229C25.8946 132.652 41.7108 45.4804 143.369 11.663Z" fill="#E5491F" stroke="#E5491F" />
		</svg>
	</div>
</div>

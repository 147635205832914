import { Config } from '../../config'
import { SortDirection } from '@angular/material/sort'

// 06.07.2017 per i medici, gestito solo per NS
export class Settings {
	// AI_TYPE
	static DSS = 'dss'
	static MCS = 'mcs'
	static NONE = 'none'

	offline_days: number

	anamnesis_group: number
	impact_anamn_group: number
	max_colleagues: number // 24.02.2021

	brand: string
	remote_support: string // 19.09.2018   {Y,N}
	recovery: string //   Y,N,B
	upload_exams: string // 12.05.2021   {Y,N,D}
	syncr_patients: string // 12.05.2021   {Y,N}
	updates_enabled: string // 12.05.2021   {Y,N}
	shared_folder: string // 17.10.2017    {Y,N}
	dicom_level: string

	p_self_reg: string // {Y,N}

	diagnosis_group: number // 14.10.2021
	telerefract: string // 29.04.2022 Y/N

	ai_type: string // 27.09.2022

	// solo per il fundus
	mosaic: string // 28.02.2018
	cup_to_disc: string // 28.02.2018

	// questi li puo' cambiare direttamente l'utente
	pdf_format: string // 14.02.2022
	date_format: string
	lang: string

	ecommerce: string //{Y,N}
	free_trial_available: string //{Y,N,U}

	address_on_report: string //{Y,N}

	constructor(RawUsDev?) {
		// default che appaiono nella createUser
		// poi semmai sovrascrivo se arrivano

		this.offline_days = 1
		this.anamnesis_group = 1
		this.impact_anamn_group = 1000

		//this.max_colleagues = 100 // default alto
		this.max_colleagues = 20 // 20.01.2023 ridotto

		this.brand = Config.BR_DEFAULT //14.10.2021

		this.upload_exams = 'Y'
		this.syncr_patients = 'Y'
		this.updates_enabled = 'Y'
		this.shared_folder = 'Y'

		this.cup_to_disc = 'N'
		this.mosaic = 'N'

		this.remote_support = 'N'
		this.recovery = 'N'
		this.dicom_level = 'N'

		this.p_self_reg = 'N'

		this.diagnosis_group = 0
		this.telerefract = 'N'
		this.ai_type = 'none' // default

		this.pdf_format = Config.PDF_FORMATS[0] // "A4";// 14.02.2022
		this.date_format = Config.DATE_FORMATS[0] // "MM/DD/YYYY";  // default americano
		this.lang = 'en' // 16.02.2022

		this.ecommerce = 'N'
		this.free_trial_available = 'N'

		this.address_on_report = 'Y'

		// 12.05.2021 dovrebbe funzionare se non ci sono sotto-oggetti complessi
		// corrispondenza con i nomi e var

		if (RawUsDev) {
			// console.log(RawUsDev)
			//var myJsonObj = JSON.parse(RawUsDev);  // KO
			// 08.11.2021   AGGIORNAMENTO ANGULAR
			// var myJsonObj = angular.copy(RawUsDev); // e' gia' parserizzato
			var myJsonObj = { ...RawUsDev }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (!this.brand)
				// 14.10.2021 patch
				this.brand = Config.BR_DEFAULT
		} //else {
		//console.log("(Setting - default values)");
		//}

		//console.log("(Setting - costruttore) "+this.toString());
		//console.log("(Setting - costruttore) mosaic: "+this.mosaic+" maxB:"+this.max_colleagues);
	}

	public isFreeTrialAvailable(): boolean {
		return this.free_trial_available == 'Y'
	}
}

// 25.05.2021 per gli utenti manager
export class AdmSettings {
	models: string // per ora partiamo con uno, TODO elenco
	categories: string // 25.10.2021
	diagonal: string // 25.03.2022
	lintel: string

	constructor(rawObj?) {
		this.models = ''
		this.categories = ''
		this.diagonal = 'N'
		this.lintel = 'N'

		if (rawObj) {
			//console.log(rawObj);  // 14.06.2021 solo per test iniziale
			this.models = rawObj.models
			this.categories = rawObj.categories

			this.diagonal = rawObj.diagonal
			this.lintel = rawObj.lintel
		}

		// capire se funziona o fa danni...
		if (this.categories == '') {
			this.categories = '*'
		}
	}
}

// 10.03.2022 per ora questi 2 campi, arrivano sui settings
// ma possono essere modificati dall'utente nella pg profile
export interface Preferences {
	pdf_format: string
	date_format: string
	lang: string
	address_on_report: string
}

export class TablePrefs {
	currentPage: number
	itemsPerPage: number
	sort: string
	dir: SortDirection
	filter: string
	empty: boolean
	gradingStatus: string
	graders: string
	operators: string
	req_date: Date
	due_date: Date
	rep_date: Date
	filterTab: string

	constructor() {
		this.currentPage = 0
		this.itemsPerPage = 10
		this.sort = ''
		this.dir = 'desc'
		this.filter = ''
		this.empty = true
		this.gradingStatus = ''
		this.graders = ''
		this.operators = ''
		this.req_date = null
		this.due_date = null
		this.rep_date = null
		this.filterTab = ''
	}
}

import { Component, Input } from '@angular/core'
import { Config } from 'src/config'
import { environment } from 'src/environments/environment'
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf],
})
export class LoaderComponent {
	@Input() activateSpinner: boolean
	loaderVisionix: boolean

	constructor() {
		this.activateSpinner = true
		this.loaderVisionix = false

		if (environment.brand == Config.BR_DEFAULT || environment.brand == Config.BR_RDS) {
			this.loaderVisionix = true
		}
	}
}

import { Util } from './util.model'

export interface Countries {
	alpha2: string
	alpha3: string
	name: string
	dial_code: string
	//isocode: number;
}

export class Country {
	alpha2: string
	alpha3: string
	name: string
	dial_code: string
	//isocode: number;  // sul DB e' varchar

	constructor(rawObj?) {
		this.alpha2 = ''
		this.alpha3 = ''
		this.name = ''
		this.dial_code = ''
		//this.isocode = 0;

		// gestisce i campi con lo stesso nome
		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}

		// patch per le bandiere
		this.alpha2 = this.alpha2.toLowerCase()
	}

	// 15.09.2022 patch per pregresso
	static getCodeFromDescr(descr: string) {
		let code = ''

		if (Util.equalsIgnoreCase(descr, 'Italy') || Util.equalsIgnoreCase(descr, 'Italia') || descr == 'IT') {
			code = 'ITA'
		} else if (Util.equalsIgnoreCase(descr, 'Spain')) {
			code = 'ESP'
		} else if (Util.equalsIgnoreCase(descr, 'GERMANY')) {
			code = 'DEU'
		} else if (Util.equalsIgnoreCase(descr, 'Israel')) {
			code = 'ISR'
		}

		return code
	}
}

export class DBCountrySettings {
	isEditing: boolean
	otp_email: string // Y o N
	p_self_reg: string // Y o N
	name: string
	country: string

	constructor(rawObj?) {
		this.isEditing = false
		this.otp_email = 'N'
		this.p_self_reg = 'N' //valido per la country
		this.name = ''

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}

	isOtpViaEmail() {
		return this.otp_email == 'Y'
	}
}

import { Config } from '../../config'
import { ExamType } from './exam.model'
import { DateParser } from './dateParser.model'
//import { totalmem } from "os";
import { Util } from './util.model'
//import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

export class VisitJson {
	visit_id: number
	patient_id: number
	name: string
	visit_date: string
	created_by: number // the doctor_id
	device: string
	device_sn: string
	has_xml: boolean
	is_visible: string // "Y"
	ai_reviewed: string // "Y" or "N"
	consent_id: number
	examTypes: ExamType[]
	grading_request: string // data
	visitExams: ExamType[]

	can_ask_AI: boolean
	can_ask_HG: boolean
	can_see: boolean
	anamnesis_percentage: number
	//exam_types: [{…}]
	//visit_exams: [{…}]
}

export interface VisitsResponse {
	visits: VisitJson[]
}

export class Visit {
	static idGen = 0 // ??
	id: number
	patient_id: number
	name: string //this is used to filter
	date: Date // e' visit_date
	//exams: Exam[];
	examTypes: ExamType[] // elenco fatto con i distinct type
	visitExams: ExamType[] // elenco con i singoli id, anche su stesso type  // 22.04.2020
	is_deleted: string
	is_valid: string
	public is_visible: string
	is_selected_HG: boolean
	is_selected_AI: boolean
	is_waiting_HG: boolean // usato per quando aspetto la risposta della promise all'invio della hg request
	is_waiting_PDF: boolean // usato per quando aspetto la risposta della promise della richiesta report pdf
	grading_request: Date
	ai_reviewed: string

	has_xml: boolean // 01.09.2020
	xml_blob: string // di solito nullo, usato solo nel download

	device: string
	device_sn: string

	descrDel: string // 31.03.2017 valorizzo solo per il distrib --ls
	isPupilOnly: boolean // per nasconderla   19.11.2020

	hasBeenReviewed: boolean // 27.09.2022
	consent_id: number

	can_ask_AI: boolean
	can_ask_HG: boolean
	can_see: boolean
	anamnesis_percentage: number

	constructor(rawObj?) {
		this.id = 0
		this.name = ''
		this.date = null
		this.device = ''
		this.device_sn = ''
		this.is_deleted = 'N' //?
		this.is_valid = 'Y' //?
		this.is_visible = 'N' // N = non richiesto, Y = visita RICHIESTO IL GRADING , H =
		this.is_selected_HG = false
		this.is_selected_AI = false
		this.is_waiting_HG = false
		this.is_waiting_PDF = false
		this.grading_request = null
		this.ai_reviewed = 'N'
		this.examTypes = [] // vuoto, non nullo
		this.visitExams = []
		this.has_xml = false
		this.xml_blob = null // o "" ?
		this.isPupilOnly = false //?
		this.hasBeenReviewed = false // VISITA GIÁ GRADATA
		this.consent_id = null //?

		this.can_ask_AI = false
		this.can_ask_HG = false
		this.can_see = false
		this.anamnesis_percentage = 0
	

		if (rawObj != null) {
			this.id = rawObj.visit_id
			this.patient_id = rawObj.patient_id
			this.name = rawObj.name
			this.date = DateParser.parseDate(rawObj.visit_date)
			this.device = rawObj.device
			this.device_sn = rawObj.device_sn

			this.is_visible = rawObj.is_visible
			this.grading_request = DateParser.parseDate(rawObj.grading_request)

			this.ai_reviewed = rawObj.ai_reviewed
			this.consent_id = rawObj.consent_id

			this.can_ask_AI = rawObj.can_ask_AI
			this.can_ask_HG = rawObj.can_ask_HG
			this.can_see = rawObj.can_see
			this.anamnesis_percentage = rawObj.anamnesis_percentage

			if (rawObj.is_deleted) {
				this.is_deleted = rawObj.is_deleted
			}

			if (rawObj.is_valid) {
				this.is_valid = rawObj.is_valid
			}

			// usato per visualizzare gli esami sulla riga della visita, senza duplicati Right/left
			if (rawObj.exam_types != null) {
				// 19.01.2022 per togliere duplicati,
				// passo su map, poi rimetto su array
				let myMap = new Map<string, ExamType>()

				for (let i = 0; i < rawObj.exam_types.length; i++) {
					var et = new ExamType(rawObj.exam_types[i])

					// 19.11.2020 hide pupil ? no, altrim. non si capisce xche' nn cliccabile
					if (myMap.has(et.exam_type)) {
						// tengo quello con isRevieved = Y
						if (et.is_reviewed == 'Y') {
							myMap.delete(et.exam_type) // questo aveva 'N'
							myMap.set(et.exam_type, et)
						}
					} else {
						myMap.set(et.exam_type, et)
					}

					// 27.09.2022
					if (et.is_reviewed == 'Y') {
						this.hasBeenReviewed = true
					}
				}

				// 19.01.2022 rimetto la map su array
				this.examTypes = Array.from(myMap.values())
				/*
        for(let key of myMap.keys()){
          let et = myMap.get(key);
          this.examTypes.push(et);
        }*/

				// 27.09.2022
				Util.debug('(visit) id: ' + this.id + ' has been reviewed: ' + this.hasBeenReviewed)
			}

			// 22.04.2020 elenco puntuale degli esami, di solito 2 per tipo, right + left
			if (rawObj.visit_exams != null) {
				var hasPupil = false
				for (let i = 0; i < rawObj.visit_exams.length; i++) {
					var myElem = rawObj.visit_exams[i]
					// 19.11.2020 hide pupil
					if (myElem.exam_type != Config.EXM_PUPIL) {
						var ve = new ExamType(myElem)
						this.visitExams.push(ve)
					} else {
						hasPupil = true // almeno uno
					}
				}

				if (hasPupil && this.visitExams.length == 0) {
					this.isPupilOnly = true
					console.log('(visit) pupil Only! id ' + this.id + ' ' + this.name)
				}

				// anticipato qui, TODO se ha altri esami oltre il pupil ?
				if (hasPupil) {
					this.is_visible = 'H' // hidden
				}
			}

			if (this.is_deleted == 'Y') this.descrDel = 'DEL_'
			else if (this.is_valid == 'N') this.descrDel = 'INV_'
			else this.descrDel = ''

			if (rawObj.has_xml) {
				// 01.09.2020
				this.has_xml = true
			}
		}
		//console.log("(visit) id "+this.id+" "+this.name);
	}

	// ritorna array semplice con gli exam_types, anziche' gli oggetti examTypes
	public getTypes(): string[] {
		var list = []
		for (let i = 0; i < this.examTypes.length; i++) {
			var val = this.examTypes[i].exam_type
			list.push(val)
		}
		// 30.09.2020
		//console.log("[D visit] id: "+this.id+" types: "+this.typesToString());
		return list
	}

	// ritorna unica stringa con tutti i tipi
	public typesToString() {
		var list = ''
		for (let i = 0; i < this.examTypes.length; i++) {
			var val = this.examTypes[i].exam_type
			list += val + ';'
		}
		return list
	}

	public hasPupilOnly() {
		return this.isPupilOnly
	}

	// 09.09.2021
	// escludere i pupil ?
	public isEmpty() {
		var totExams = 0
		if (this.visitExams != null) {
			totExams = this.visitExams.length
		}
		return totExams == 0
	}

	// 07.02.2022
	// se la visita e' visible e nessuno dei suoi esami e' stato refertato
	public hasPendingReports() {
		let almenoUno = false
		for (let i = 0; i < this.visitExams.length; i++) {
			if (this.visitExams[i].is_reviewed) {
				almenoUno = true
				break
			}
		}

		return this.is_visible && !almenoUno
	}

	// 07.02.2022
	public canAskReview() {
		let flag = false

		// requisito minimo
		flag = !this.isEmpty() && !this.is_visible && !this.hasPupilOnly()

		// se ci sono visite "completate" (=con esami fatti) ma non visibili al ref
		// soglia di tempo per attendere che non ci siano altri esami ?

		return flag
	}

	/*
  static createVisit(rawObj) {    
    var result = new Visit(rawObj);    
    return result;   
  }
  */

	static createVisitsList(response /*: Contracts.VisitsResponse*/) {
		var result = []

		//var myResp = response.data.visits;
		var myResp = response.visits // 15.12.2021

		for (var i = 0; i < myResp.length; i++) {
			var visit = new Visit(myResp[i])
			if (!visit.hasPupilOnly()) {
				// 19.11.2020 nascondo
				result.push(visit)
			}
		}
		return result
	}
}

import { IconDefinition, IconName, IconProp } from '@fortawesome/fontawesome-svg-core'

import { faBars, faCamera, faUsers } from '@fortawesome/free-solid-svg-icons'
import { faBell, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
//import { amazon } from '@fortawesome/free-brands-svg-icons';

import { UserType } from './user.model'

export class Language {
	name: string
	label: string // it_IT, en,...
	short: string // it, en
	callback: () => void

	// 30.03.2022 aggiunta label
	static createLanguage(name: string, callback: () => void, label?: string): Language {
		//var result: Language;
		let result = new Language(name, label)
		//result.name = name;
		//result.label = label;
		result.callback = callback
		return result
	}

	// 30.03.2022 esteso con param nel costruttore
	constructor(name: string, label?: string) {
		this.name = name
		if (label != null) {
			this.label = label
			this.short = label.substring(0, 2)
		}
	}
}

export class Section {
	key: string
	callback: () => void
	name: string
	visibleBy: UserType[]
	icon: IconDefinition

	static createSection(key: string, callback: () => void, name: string, visibleBy: UserType[] = [], iconDef?: IconDefinition): Section {
		var result: Section
		result = new Section()
		result.key = key
		result.callback = callback
		result.name = name

		result.visibleBy = visibleBy

		if (iconDef) result.icon = iconDef
		else result.icon = faBars

		return result
	}

	constructor() {}
}

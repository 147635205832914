import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { NgbModal, ModalDismissReasons, NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute } from '@angular/router'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { RecoverPukModal } from './recoverPuk.modal'
import { SessionStatus, User } from '../../models/user.model'
import { Util } from '../../models/util.model'

// safari check
// check device type and OS
import { UAParser } from 'ua-parser-js'
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FormControl, FormGroup, NgForm, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FaIconComponent } from '@fortawesome/angular-fontawesome'
import { LoaderComponent } from '../../elements/loader/loader.component'
import { NgIf, NgClass } from '@angular/common'

const parser = new UAParser()
const resultB = parser.getResult()
const browserN = resultB.browser.name

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [NgIf, LoaderComponent, FormsModule, ReactiveFormsModule, NgClass, FaIconComponent, NgbTooltip, TranslateModule],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('username') usrn!: ElementRef

	showPwd: boolean
	loginForm: FormGroup

	// guestUser: User // model usato nel form
	loginStatus: SessionStatus

	currentAction: string // per recover pwd da puk
	currentModal
	recoverErrMsg: string

	btnProceedDisabled: boolean
	validPwd: boolean

	// cache controller
	oldFeBuild: boolean
	warning: boolean
	oldFeMessage: string

	// icons
	faTriangleExclamation = faTriangleExclamation

	// per gestione RDS
	customer: string

	ar: any // 09.06.2022

	faEyeOpen = faEye
	faEyeClosed = faEyeSlash

	//public cust_RDS = 'RDS'; // costante da concordare con il cliente, usiamo il brand

	constructor(public session: SessionService, public translator: TranslateService, public modalService: NgbModal, private activatedRoute: ActivatedRoute) {
		this.showPwd = false
		//this.session = sessionService;
		// this.guestUser = new User(null)
		this.loginStatus = SessionStatus.NOT_LOGGED

		this.loginForm = new FormGroup({
			username: new FormControl('', [Validators.required, Validators.minLength(3)]),
			password: new FormControl('', [Validators.required, Validators.minLength(3)]),
		})

		Util.debug('(login) - costruttore')

		// 05.05.2022
		this.oldFeBuild = false
		this.oldFeMessage = ''
		this.warning = false

		// 28.04.2022 gestione step2
		if (this.session.getTempToken().length > 0) {
			this.openRecoverPukModal()
		} else {
			// 14.05.2024 spostato qui altrimenti il link temporaneo di recover con puk non funziona,
			// se si ritrova il refreshTime nella url
			//if(Config.isProductionMode){  // 25.08.2022 temp per test
			//if(!session.isDev()){  // 31.03.2023 abilitare solo mentre si sviluppa in locale!
			this.verifyFeBuild() // 13.05.2022 attivato
			//}
		}

		// 05.05.2022 gestione RDS, qui o su ngOnInit ?
		this.ar = null // 13.06.2022
		this.loadUrlParameters()
	}

	ngOnInit(): void {
		Util.debug('(login) - ngOnInit ')

		// 31.05.2022 if already logged
	}

	ngAfterViewInit(): void {
		this.usrn.nativeElement.focus()
	}

	clickTogglePassword() {
		this.showPwd = !this.showPwd
	}

	// 05.05.2022
	private loadUrlParameters() {
		Util.debug('(login) - loadUrlParameters')
		this.customer = ''
		//this.username = "";

		if (this.activatedRoute != null) {
			// 09.06.2022 added test
			//this.ar = this.activatedRoute.paramMap.subscribe((params) => {   // 13.06.2022 ko
			this.ar = this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['customer']
				if (myParam != null && myParam.length > 0) {
					this.customer = myParam
					Util.debug('(login) received customer: ' + myParam)
				}

				// pre-fill form di login, procedo in automatico
				myParam = null
				myParam = params['username']
				if (myParam != null && myParam.length > 0) {
					this.loginForm.get('username').setValue(myParam)
					Util.debug('(login) received username ' + myParam)
				}

				/* NON sicuro!
				myParam = null;
				myParam = params['pwd'];            
				if(myParam != null && myParam.length>0){  
				if(this.customer == cust_RDS){    
					this.guestUser.password = myParam;        
					Util.debug("(login) RDS - received pwd, proceed with login... ");
					this.automaticLogin();
				}
				}  
			  */

				// TODO valutare se puo' servire anche ad altri
				if (this.customer == Config.BR_RDS) {
					// 31.05.2022 salvo il patient id per andare subito li', dopo la login
					myParam = null
					myParam = params['patient']
					if (myParam != null && myParam.length > 0) {
						//this.targetPatient = myParam;
						Util.debug('(login) received target patient id ' + myParam)

						if (parseInt(myParam) > 0) {
							// 14.06.2022
							this.session.setTargetPatient(myParam)

							/* qui e' troppo presto! lo fa la session finita la login, su getLandingPage
							Util.debug("(login) redirecting to visits page...");
							*/
						}
					}

					// go on patient edit directely
					myParam = null
					myParam = params['edit']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target patient edit ' + myParam)
						if (myParam) {
							this.session.setTargetPatientEdit()
						}
					}

					// go on patient anamnesis directely
					myParam = null
					myParam = params['anamnesi']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target patient edit anamnesi' + myParam)
						if (myParam) {
							this.session.setTargetPatientEditAnamnesis()
						}
					}

					// 07.07.2022 salvo il reportId per andare subito li', dopo la login
					myParam = null
					myParam = params['report']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target report id ' + myParam)
						if (parseInt(myParam) > 0) {
							this.session.setTargetReport(myParam)
						}
					}

					// 24.08.2022 salvo il AIreportId per andare subito li', dopo la login
					myParam = null
					myParam = params['aireport']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target AIreport id ' + myParam)
						if (parseInt(myParam) > 0) {
							this.session.setTargetAiReport(myParam)
						}
					}

					// go on balance directely
					myParam = null
					myParam = params['balance']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target balance ' + myParam)
						if (myParam) {
							this.session.setTargetBalancePage()
						}
					}

					// go on balance directely
					myParam = null
					myParam = params['plan']
					if (myParam != null && myParam.length > 0) {
						Util.debug('(login) received target plan ' + myParam)
						if (myParam) {
							this.session.setTargetPlanPage()
						}
					}

					// 07.07.2022 se riutilizzo pagina precedente, in cui ero gia' loggato
					if (this.session.isLogged()) {
						if (this.loginForm.get('username').value == this.session.getUsername()) {
							this.session.getLandingPage()
						}
					}
				}

				// 30.03.2023 apro recover con puk, se richiesto arrivando da ecomm-login-page
				myParam = null
				myParam = params['targetpage']
				if (myParam != null && myParam.length > 0) {
					Util.debug('(login) received target page ' + myParam)

					if (myParam == 'recover') {
						return this.openRecoverPwdModal()
					}
					// TODO: se ecommerce -> redirect dopo la login
				}
			})
		}
	}

	/*
	  // 16.05.2022
	  private automaticLogin(){
		  if(this.customer == this.cust_RDS){  
			  Util.debug("(automaticLogin) - customer: "+this.customer);		
			  if(this.guestUser.username != "" && this.guestUser.password != "") {      
				  Util.debug("(automaticLogin) RDS - received pwd, proceed with login... ");
				  this.login();
			  }
		  }
	  }
	*/

	// 05.05.2022
	private verifyFeBuild() {
		let myBuild = Config.BUILD
		let srvBuild = ''

		this.session
			.getAllBuilds()
			.then((resp: buildResponse[]) => {
				console.log(resp)

				this.session.nexusBuils = resp

				if (!this.session.isBrand(Config.BR_DEFAULT)) {
					return
				}

				let feBuild = resp.filter((el) => el.item == 'FeWeb2')

				if (feBuild.length > 0) {
					srvBuild = feBuild[0].build
					if (Util.equalsIgnoreCase(myBuild, srvBuild)) {
						console.log('(verifyFeBuild) ok latest build: ' + myBuild)
					}
				}

				if (srvBuild != '' && !Util.equalsIgnoreCase(myBuild, srvBuild)) {
					let currUrl = window.location.href // solo fino alla porta, poi lo inserisce prima di /login ma funziona lo stesso anche se rimane

					//console.log("(verifyFeBuild) o:"+window.location.origin+" h:"+window.location.hash);
					//console.log("(verifyFeBuild) o:"+window.location.origin+" p:"+window.location.path +" s:"+ window.location.search);

					/* TODO: 
				The solution to this issue is, to execute a POST request to the current location 
				as this always makes the browser to reload everything.
				https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated/59959604#59959604
				
				*/

					// ATT: evitare loop!
					if (currUrl.indexOf('refreshTime') < 0) {
						// solo la prima volta
						console.log('(verifyFeBuild) found old build, try to reload...')
						//location.reload(true); // ko

						let timestamp = Date.now().toString() // es: 1651753593432

						if ('URL' in window) {
							let url = new URL(currUrl)
							url.searchParams.set('refreshTime', timestamp)
							window.location.href = url.toString()
						} else {
							// compatibilita' con vecchi browsers
							// window.location.href =
							// 	window.location.origin +
							// 	window.location.pathname +
							// 	window.location.search +
							// 	(window.location.search ? '&' : '?') +
							// 	'refreshTime=' +
							// 	timestamp +
							// 	window.location.hash
						}
					} else {
						this.oldFeMessage = this.translator.instant('LOGIN.ERROR_OLD_VERSION', { v1: srvBuild })
						this.oldFeBuild = true // activate msg in the page
					}
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	// 05.05.2022 form opzionale, per RDS
	login() {
		// console.log(myForm)
		this.loginStatus = 1

		this.loginForm.disable()
		// NgForm
		let usrN: string = this.loginForm.get('username').value
		let pwd: string = this.loginForm.get('password').value

		// if (myForm) {
		// 	usrN = myForm.value.username
		// 	pwd = myForm.value.password
		// } else {
		// 	usrN = this.guestUser.username
		// 	pwd = this.guestUser.password
		// }

		Util.debug('(login) username value: ' + usrN)

		// 03.08.2022 trim empty spaces around values
		usrN = usrN.trim()
		pwd = pwd.trim()

		//this.session.login(this.myForm.username, this.myForm.password);
		//this.session.login(myForm.username, myForm.password);
		this.session.login(usrN, pwd).finally(() => {
			this.loginStatus = this.session.getStatus()
			if (this.loginStatus != 2) {
				this.loginForm.enable()
			}

			// console.log(this.loginStatus)
		})
	}

	// 07.09.2017 richiede il token via mail - STEP1
	openRecoverPwdModal() {
		Util.debug('(login) - openRecoverPwdModal - step1')
		//alert("recover - not available yet");

		this.currentAction = 'recoverPwd'
		this.recoverErrMsg = '' // pulizia
		this.btnProceedDisabled = false

		Util.debug('COMP (openRecoverPwdModal) going to open it... action: ' + this.currentAction)

		this.currentModal = this.modalService.open(RecoverPukModal, { size: 'lg' })
		this.currentModal.componentInstance.currentAction = 'recoverPwd' // STEP 1

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(ris) => {
				Util.debug('login - step1 - After modal closed: ')
			},
			(reason) => {
				let ris = '(login) Dismissed ' + this.getDismissReason(reason)
				Util.debug(ris)
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return 'with: ' + reason
		}
	}

	// 23.03.2017 step 2: ho gia' il token temporaneo, devo fornire il puk x la verifica e poi proseguire
	openRecoverPukModal() {
		this.currentAction = 'recoverFromPuk' // step 2
		this.recoverErrMsg = '' // pulizia
		this.btnProceedDisabled = false

		// 19.11.2018 verifica subito validita' del token
		//console.log("(openRecover) verifica se token scaduto");
		this.session
			.verifyTempToken()
			.then(() => {
				// apre il modal solo se ok token

				this.currentModal = this.modalService.open(RecoverPukModal, { size: 'lg' })

				this.currentModal.componentInstance.currentAction = 'recoverFromPuk' // step 2

				/*
      this.currentModal = this.modal.open({
        controller: ()=> {return this},
        controllerAs: "modal",
        replace: true,
        size: "lg",
        template: TPL.modals.recoverFromPuk
      });
      */

				// arriva qui facendo la close del modal
				this.currentModal.result.then(
					(changedPat) => {
						console.log('login - step2 - After modal closed: ')
						//this.submitForm(changedPat);
					},
					(reason) => {
						let ris = '(login) step2 Dismissed ' + this.getDismissReason(reason)
						console.log(ris)
					}
				)
			})
			.catch((err) => {
				//var msg = (err.data)? err.data.error : err.toString();
				//this.recoverErrMsg = "Error, Token expired: "+msg;  // 10.03.2021
				// 07.06.2021 tolto msg, stampava un obj
				this.recoverErrMsg = this.translator.instant('RECOVER_PUK.TOK_EXPIRED')
				alert(this.recoverErrMsg)
				return
			})
	}

	// 31.03.2021 portata su Util, richiamata anche da altri [ls]
	// validatePwd(value) {
	// 	this.validPwd = Util.validatePwd(value)
	// }

	// 09.06.2022
	ngOnDestroy() {
		//if(this.activatedRoute && this.activatedRoute.queryParams){
		if (this.ar != null) {
			//13.06.2022
			this.ar.unsubscribe()
			Util.debug('login - destroy - unsubscribe done')

			// 04.01.2022 spostata su session, initAfterLogin()
			// check if localstorage Language is the same as profile language
		} else {
			Util.debug('login - destroy')
		}
	}
}

export interface buildResponse {
	item: string
	build: string
	released: string
}

// import { NgModule } from '@angular/core'
import { provideRouter, Routes } from '@angular/router'

import { LoginComponent } from '../app/component/login/login.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AuthGuard } from './service/auth-guard.service'

const routes: Routes = [
	{ path: '', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'patients', loadComponent: () => import('./component/patients/patientList.component').then((m) => m.PatientListComponent), canActivate: [AuthGuard] },
	// { path: 'visits', component: VisitListComponent },
	{ path: 'operators', loadComponent: () => import('./component/doctors/doctorList.component').then((m) => m.DoctorListComponent) },
	{ path: 'colleagues', loadComponent: () => import('./component/doctors/doctorList.component').then((m) => m.DoctorListComponent) },
	{ path: 'admins', loadComponent: () => import('./component/admins/adminList.component').then((m) => m.AdminListComponent) },
	{ path: 'profile', loadComponent: () => import('./component/profile/profile.component').then((m) => m.ProfileComponent) },
	{ path: 'createUser', loadComponent: () => import('./component/users/createUser.component').then((m) => m.CreateUserComponent) },
	{ path: 'activation', loadComponent: () => import('./component/activation/activation.component').then((m) => m.ActivationComponent) },
	{ path: 'agreement', loadComponent: () => import('./component/agreement/agreement.component').then((m) => m.AgreementComponent) },
	// { path: 'verifyPuk', component: VerifyPukComponent },
	{ path: 'telerefract', loadComponent: () => import('./component/telerefract/telerefract.component').then((m) => m.TelerefractComponent) },
	{ path: 'remotes', loadComponent: () => import('./component/remotes/remoteList.component').then((m) => m.RemoteListComponent) },
	{ path: 'relations', loadComponent: () => import('./component/relations/relations.component').then((m) => m.RelationsComponent) },
	{ path: 'statistics', loadComponent: () => import('./component/statistics/statistics.component').then((m) => m.StatisticsComponent) },
	{ path: 'devices', loadComponent: () => import('./component/devices/devices.component').then((m) => m.DevicesComponent) },
	{ path: 'updates', loadComponent: () => import('./component/updates/updates.component').then((m) => m.UpdatesComponent) },
	{ path: 'subscription', loadComponent: () => import('./component/subscription/subscription.component').then((m) => m.SubscriptionComponent) },
	{ path: 'trial', loadComponent: () => import('./component/trial/trial.component').then((m) => m.TrialComponent) },
	{ path: 'graders', loadComponent: () => import('./component/clinics/clinics.component').then((m) => m.ClinicsComponent) },
	{ path: 'dashboard', loadComponent: () => import('./component/dashboard/dashboard.component').then((m) => m.DashboardComponent) },
	{ path: 'locations', loadComponent: () => import('./elements/location/location.component').then((m) => m.LocationComponent) },
	{ path: 'sap', loadComponent: () => import('./component/sap/sap.component').then((m) => m.SapComponent) },

	{ path: 'dbsettings', loadComponent: () => import('./component/dbsettings/dbsettings.component').then((m) => m.dbSettingComponent) },

	{ path: 'not-found', component: PageNotFoundComponent },

	{ path: '**', redirectTo: '/not-found' },

	// { path: '**', component: WorkingComponent },

	//{ path: '**', redirectTo: '/login' }
]

// 24.03.2022 per ricaricare in caso di cambio params, non funziona
//imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],

// @NgModule({
// 	imports: [RouterModule.forRoot(routes)],
// 	exports: [RouterModule],
// })
// export class AppRoutingModule {}

export const appRoutingProviders = provideRouter(routes)

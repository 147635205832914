import { Component, Input } from '@angular/core'
import { NgbProgressbarConfig, NgbProgressbarModule, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

export interface LoaderStatus {
	activateLoader: boolean
	max: number
	current: number
	label: string
}

@Component({
    selector: 'loader-status',
    templateUrl: './loader-status.component.html',
    styleUrls: ['./loader-status.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgbProgressbar,
        TranslateModule,
    ],
})
export class LoaderStatusComponent {
	@Input() activateLoader: LoaderStatus

	constructor(private config: NgbProgressbarConfig) {
		config.max = 100
		config.striped = true
		config.animated = true
		config.type = 'danger'
		config.height = '10px'
	}
}
